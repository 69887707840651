import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailsHead from "../components/detailshead"
import Services from "../components/services"

const AutomobileLockout = () => (
  <Layout>
    <SEO title="Automobile Lockout Services" />
    <DetailsHead title="Automobile Lockout Services"/>
    <Services/>
  </Layout>
)

export default AutomobileLockout
