import React from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

import styles from './services.module.css'
import ContactInfo from '../banner/contact-info'

const EmergencyServices = () => {
const query = useStaticQuery(graphql`
	query {
		  allStrapiAutomobileLockoutServicesManager{
		    edges{
		      node{
		        id,
		        title,
		        description,
		        feature_image{
		          publicURL
		        },
		        items{
		          id,
		          name,
		          key,
		          description,
		          icon{
		            publicURL
		          }
		        }
		      }
		    }
		  }
	  }
	`)

	let p = [];
	query.allStrapiAutomobileLockoutServicesManager.edges.forEach(({node})=>{
		p.push(node)
	})
	p = p[0];
	return (
		<>
			<div className={styles.banner}>
				<div className={styles.bannerLeft}>
					<div>
						<h2>{p.title}</h2>
						<p>{p.description}</p>
					</div>
				</div>
				<div className={styles.bannerRight}>
					<img src={p.feature_image.publicURL} />
				</div>
			</div>

			<div className={styles.container}>
				<div className={styles.wrapper}>

					{
						p.items.map((item, index)=>{
							return (
								<div className={styles.item}>
									<div className={styles.itemLeft}>
										<img src={item.icon.publicURL} alt={`sks - item number`}/>
									</div>
									<div className={styles.itemRight}>
										<h3>{item.name}</h3>
										<h4>{item.key}</h4>
										<p>{item.description}</p>
									</div>
								</div>
								)
							})
					}	
				
				</div>
			</div>
		</>
	)
}

export default EmergencyServices