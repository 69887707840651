import React from 'react'

const CI = ({styles,p}) => {
	return(
		<div className={styles.contactInfo}>
			<div className={styles.contactWrapper}>
				<img className={styles.shake} src={p.emergancy_service_call_icon.publicURL} alt={`sks - phone`}/>
				<h3 dangerouslySetInnerHTML={{__html: p.emergancy_service_call_name}}/>
			</div>
			<h2>{p.phone_no}</h2>
		</div>
	)
}

export default CI