import React from 'react'
import { Link } from "gatsby"

import styles from './detailshead.module.css'

const DetailsHead = ({title}) => {
	return (
		<>
			<div className={styles.breadcrumbContainer}>
				<div className={styles.wrapper}>
					<ol className={styles.breadcrumb}>
				        <li>
				        	<Link to={`/`}>Home</Link>
				        </li>
				        <li className="active">{title}</li>
				    </ol>
				    <h1 className={styles.mainTitle}>{title}</h1>
			    </div>
			</div>		
		</>
	)
}

export default DetailsHead